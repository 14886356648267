export default class CampaignSuggestion {
  /**
   * @param {String} name
   * @param {number} id
   */
  constructor(name, id) {
    this.name = name;
    this.id = id;
  }
}
